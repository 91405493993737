import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import InnovationExplorer from "../components/InnovationExplorer"
import PageIntro from "../components/PageIntro"

export const ReadTheReportPageTemplate = ({ content, isPreview = false }) => {
  return (
    <ReadTheReportWrapper>
      {content.map((row, index) => (
        <div className="row" key={index}>
          <div
            className="content"
            style={{ backgroundColor: row.background_color }}
          >
            <h2>{row.heading}</h2>
            <a
              className="button"
              href={row.call_to_action_href}
              target="_blank"
            >
              {row.call_to_action}
            </a>
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${row.image})` }}
          ></div>
        </div>
      ))}
    </ReadTheReportWrapper>
  )
}

const ReadTheReport = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <SEO title={"Read the report"} />
      <ReadTheReportPageTemplate
        content={frontmatter.page_content}
      ></ReadTheReportPageTemplate>
    </Layout>
  )
}

const ReadTheReportWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image content";
    &:nth-child(2n) {
      grid-template-areas: "content image";
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      /* grid-template-areas: "content" !important; */
      grid-template-areas:
        "image"
        "content" !important;
    }

    .content {
      grid-area: content;
      box-sizing: border-box;
      padding: 3rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: white;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 2rem;
        max-width: 50%;
      }

      a {
        color: #f3f3f3;
        border-color: #f3f3f3;
        transition: all ease 0.3s;
        &:hover {
          color: #3c3c3c;
          background-color: #f3f3f3;
        }
      }
    }

    .image {
      grid-area: image;
      aspect-ratio: 1/1;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "read-the-report-page" } }
    ) {
      frontmatter {
        page_content {
          heading
          background_color
          call_to_action_href
          call_to_action
          image
        }
      }
    }
  }
`

export default ReadTheReport
